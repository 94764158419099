import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

// Background Image component
import Background from "../backgroundImage"
import BackgroundImg from "gatsby-background-image"

// Iconss
import { Search, Dropdown } from "../icons"

const Blog = ({ data: { title, blogs }, filterCategory, customNav }) => {
  const [filteredBlogs, setFilteredBlogs] = useState(blogs)
  const [dropdown, setDropdown] = useState(false)
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState("")

  const urlParams =
    typeof window !== `undefined`
      ? new URLSearchParams(window?.location?.search)
      : null
  const param = urlParams && urlParams.get("category")

  let categoriesSet = []
  blogs.map((blog, index) => categoriesSet.push(...blog.categories))
  const filteredCategories = [...new Set(categoriesSet)].sort()
  filteredCategories.unshift("All Categories")

  const searchInput = e => {
    setSearch(e.target.value)
  }
  const selectDropdown = category => {
    setCategory(category)
    setDropdown(false)
  }

  useEffect(() => {
    let filterBlog
    if (category === "" || category === "All Categories") {
      filterBlog = blogs.filter(
        val => val.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
    } else {
      filterBlog = blogs.filter(
        val =>
          val.title.toLowerCase().indexOf(search.toLowerCase()) >= 0 &&
          val.categories.includes(category)
      )
    }

    setFilteredBlogs(filterBlog)
  }, [search, category])

  useEffect(() => {
    if (filterCategory) {
      setCategory(filterCategory)
    }
  }, [])

  const getFormatedDate = publishedDate => {
    const date = new Date(publishedDate)
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  return (
    <>
      <Background className={`blogSection ${customNav ? "pt-8 md:pt-8" : ""}`}>
        <div className="max-w-6xl px-4 pt-8 mx-auto md:pt-16 md:px-8 xl:px-0">
          <h1 className="py-4 text-3xl font-bold tracking-tight text-white uppercase md:text-5xl siteFont">
            {title}
          </h1>
          <hr className="my-6 md:my-10 xl:mx-auto" />
          <div className="items-center justify-center max-w-2xl mx-auto text-xl font-bold text-white md:flex md:text-3xl">
            <div className="w-full mb-4 md:w-1/2 md:mb-0">
              <h2 className="mb-2 siteFont md:mb-0">Search</h2>
              <div className="blog-input">
                <input
                  type="text"
                  value={search}
                  onChange={e => searchInput(e)}
                  placeholder="Search Here..."
                />
                <Search className="icon" />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <h2 className="mb-1 siteFont">Categories</h2>
              <div className="blog-input">
                <div
                  className="overlay"
                  style={{
                    position: dropdown ? "fixed" : null,
                    display: dropdown ? "block" : "none",
                  }}
                  role="button"
                  onClick={() => setDropdown(false)}
                  onKeyDown={() => setDropdown(false)}
                />
                <div
                  className="mb-2 dropdown md:mb-0"
                  onClick={e => {
                    e.stopPropagation()
                    return setDropdown(true)
                  }}
                  role="button"
                  onKeyDown={() => setDropdown(true)}
                >
                  {category ? category : "All Categories"}
                </div>
                <div className={`dropdown-content ${dropdown ? "open" : null}`}>
                  {filteredCategories.map((category, index) => (
                    <p key={index} onClick={() => selectDropdown(category)}>
                      {category}
                    </p>
                  ))}
                </div>
                <Dropdown
                  onClick={() => setDropdown(true)}
                  className="cursor-pointer icon"
                />
              </div>
            </div>
          </div>
          <hr className="my-6 md:my-16 xl:mx-auto" />
        </div>
      </Background>
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="flex flex-wrap justify-start mt-6">
          {filteredBlogs &&
            filteredBlogs.map((blog, i) => (
              <Link
                to={`/blog/${blog.slug}`}
                key={blog.id}
                className={
                  (i === 0 ? " md:w-6/12 lg:w-4/6" : " md:w-6/12 lg:w-1/3") +
                  " md:px-3 w-full mb-8 md:mb-10"
                }
              >
                <div className="overflow-hidden rounded-lg blogItem">
                  <div className="w-full imageOverlay" />
                  <BackgroundImg
                    className="h-48 bg-no-repeat xl:h-56"
                    fluid={blog.coverImage.fluid}
                    fadeIn={false}
                    loading="eager"
                    style={{
                      // backgroundImage: "url(" + blog.coverImage.fluid.src + ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <div
                    className="h-40 px-5 py-6 bg-cardBg md:py-3 lg:py-5"
                    style={{ backgroundColor: "#1a1b1d" }}
                  >
                    <div className="flex flex-wrap Gray">
                      <p className="mr-4 text-sm font-semibold uppercase">
                        {getFormatedDate(blog.publishedDate)}
                      </p>
                      <span className="my-auto text-xs uppercase">
                        {blog.categories.slice(0, 3).join(", ")}
                      </span>
                    </div>
                    <p className="mt-2 text-2xl font-bold text-white limitText siteFont">
                      {blog.title}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          {filteredBlogs.length === 0 && (
            <div className="mx-auto mt-16 mb-16 text-2xl text-center text-white md:mb-32 md:text-3xl">
              Not Found
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Blog
