import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Blog from "../components/Blog/blog"
import ContactUs from "../components/contactUs"

// Context API data
import { AppContext } from "../store/AppContext"

const BlogPage = ({ data, location }) => {
  const globalData = useContext(AppContext)
  const { ogTitle, ogDescription, title, blogs } = data.blog
  return (
    <Layout>
      <SEO title={ogTitle || "Blog"} description={ogDescription} />
      <Blog
        data={{ title, blogs }}
        filterCategory={location?.state?.category || false}
        customNav={globalData?.state?.customNav}
      />
      <ContactUs simple />
    </Layout>
  )
}

export const query = graphql`
  query BlogPageQuery {
    blog: contentfulBlogPage {
      ogTitle
      ogDescription
      title
      blogs {
        id
        slug
        title
        heading
        coverImage {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        categories
        publishedDate
      }
    }
  }
`

export default BlogPage
